import { toast } from "react-toastify";
import ApiService from "./ApiServices";

export function formatMoney(value: number | string) {
  try {
    value = parseFloat(value.toString());
    let val = value?.toLocaleString("en-US", {
      style: "currency",
      currency: "GBP",
    });

    if (val?.includes("NaN")) {
      val = "...";
    }
    return val;

  } catch (error) {
    return "";
  }
}

export async function getPlayerBalanceStr() {
  try {
    let apiService = new ApiService();
    let data = await apiService.getBalance() as any;
    data = data.data;
    // console.log('playerb', data.availableMoneyInBankrollCurrency);
    if (data.availableMoneyInBankrollCurrency > 0) {
      return data.availableMoneyInBankrollCurrency
    }

    return data.availableMoneyInBankrollCurrency;

  } catch (error) {
    console.log(error);
    // handleErrors(error);
  }
}

export function getAuthUser(): any {
  let str = localStorage.getItem("auth");
  return str;
}

export function setAuthUser(data): any {
  localStorage.setItem("auth", data.data.jwt);
}

let currentIp = "";
export async function getCurrentIP() {
  // console.log("current ip", currentIp);

  if (!currentIp) {
    let jQuery = (window as any).$;
    return new Promise((resolve, reject) => {
      jQuery.getJSON(
        "https://888.catmonocle.cloud/auth/ip",
        (data) => {
          let ip = data.ip;
          currentIp = ip;
          resolve({
            clientIp: ip,
          });
        },
        () => {
          reject("failed to get ip address");
        }
      );
    });
  }
  return new Promise((resolve, reject) => {
    return resolve({
      clientIp: currentIp,
    });
  });
}

// export function urlParams() {
//   let url_string = window.location.href; //window.location.href
//   let url = new URL(url_string);
//
//   return {
//     token: url.searchParams.get("Token"),
//     gameType: url.searchParams.get("GameType"),
//     networkID: url.searchParams.get("NetworkID"),
//     playerID: url.searchParams.get("PlayerID"),
//     serverName: getServerName().toString(),
//     name: url.searchParams.get("DisplayName"),
//   };
// }

export function urlParams() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string) as any;
  const args =  new URLSearchParams(url.search);
  console.log('args',url.search,  args.get('Token'));

  return {
    Token: args.get("Token"),
    playerId: args.get("PlayerID"),
    gameType: args.get("GameType"),
  };
}

export function handleErrors(error) {
  console.error(error);

  if (error.response?.data?.message) {
    toast.error(error.response?.data?.message);
  } else if (error?.message) {
    toast.error(error?.message);
  } else {
    toast.error("Request failed!");
  }
}

export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function showError(err) {
  toast.error(err);
}

export function getServerName() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);
  var gType = url.searchParams.get("GameType");
    if (gType == "19") {
      return "Music";
    }
    if (gType == "21") {
      return "Hollywood";
    }
    if (gType == "20") {
      return "Noughties";
    }
    if (gType == "22") {
      return "Nineties";
    }
    if (gType == "23") {
      return "Eighties";
    }
    return "Hollywood";
}

export function validateBot(callBack: CallableFunction, actionName: string) {
  callBack();
  
  // let grecaptcha = (window as any).grecaptcha;
  // console.log(grecaptcha,"grecaptcha")

  // grecaptcha.ready(function () {
  //   grecaptcha
  //     .execute("6LdiRy8kAAAAANsgSybFjf0wIBFGB0DDClEuwyY6", {
  //       action: actionName,
  //     })
  //     .then(function (token) {
  //       callBack(token);
  //     })
  //     .catch((err) => {
  //       console.log("gr error", err);
  //       alert(
  //         "We are unable to load Google reCAPTCHA!. Please reload the game"
  //       );
  //     });
  // });
}
