import React, { useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateFiftySinglePlayer } from "../../AppSync/src/graphql/subscriptions";

export async function subscribeGame(appSyncGameId, callBack) {
  let unsub = (
    (await API.graphql(
      graphqlOperation(onUpdateFiftySinglePlayer, {
        id: appSyncGameId,
      })
    )) as any
  ).subscribe((data) => {
    data = data?.value?.data.onUpdateFiftySinglePlayer;
    callBack({ data, unsub });
  });
}
