export function getServerType() {
  // return process.env.REACT_APP_SERVER_TYPE;
  return "playtech";
}

export default class Config {
  isOnDebugMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  getBaseURL() {
    if (this.isOnDebugMode) {
      return "https://localhost:44303";
      // return "http://fifty.catmonocle.cloud";
    }

    return "";
  }

  getGameType() {
    let url_string = (window as any).location.href; //window.location.href
    let url = new URL(url_string);

    return url.searchParams.get("GameType");
  }
}
