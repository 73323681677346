import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatMoney, getPlayerBalanceStr, getServerName, validateBot } from "../../Services/HelperMethods";
import "./Countdown.scss";
import GeneralTop from "../../components/GeneralTop";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import PrizeFundBanner from "../../components/PrizeFundBanner";
import moment from "moment";
import {
  GameIdStore,
  GameStatus,
  PlayerBalanceStore,
  StartTimerStore,
  TotalJackpot,
  TotalPlayers,
} from "../../store/Store";
import ApiService from "../../Services/ApiServices";
import Reactions from "../../components/Reactions/Reactions";
import { toast } from "react-toastify";
import { start } from "repl";

export default function Countdown() {
  const history = useHistory();
  const [timer, setTimer] = useState(0);
  const [totalPlayers, setTotalPlayers] = useContext(TotalPlayers);
  const [totalJackpot, setTotalJackpot] = useContext(TotalJackpot);
  const [startTime, setStartTime] = useContext(StartTimerStore);
  const { gameId } = useParams() as any;
  const [status] = useContext(GameStatus);
  const [firstTimeStartTime, setFirstTimeStartTime] = useState(0 as any);
  const apiService = new ApiService();
  const [availableMoney, setAvailableMoney] = useContext(PlayerBalanceStore);
  let timeout;
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPlayerBalance();
    setFirstTimeStartTime(startTime)
    console.log(totalJackpot, "totalJackpot");
  }, []);

  useEffect(() => {
    if(startTime===0){
       registerForNextGame();
       setStartTime(0)
    }
    if (!startTime) return;
    const intervalId = setInterval(() => {
      setStartTime(startTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);

  }, [startTime]);

  async function getPlayerBalance() {
    let playerBalance = await getPlayerBalanceStr();
    setAvailableMoney(playerBalance);
  }

  async function registerForNextGame() {
    console.log("fire game ");
    setIsLoading(true);
    validateBot(async (token) => {
      try {
        let result = (await apiService.registerPlayerForNextGame({
          gCaptcha: token,
          Server: getServerName(),
        })) as any;
        result = result.data;
        const appsyncId = result.authResult.appSyncId;
        setAppSyncGameId(appsyncId);
        history.push("/game/" + appsyncId);
      } catch (error: any) {
        console.error(error);
        if (error.response?.data?.type == "same_user") {
          // history.push("/game/" + appSyncGameId);
          // toast.info("Already in the game, No money has been deducted")
        } else {
          // handleErrors(error); 
        }
        setIsLoading(false);
      }
    }, "PlayGame");
  }
  
  return (
    <div className="layers">
      <div className="countdown">
        <div className="top">
          <GeneralTop availableMoney={availableMoney} />
          <PrizeFundBanner jackpot={20} />
        </div>
        <div className="img-mobile"></div>

        <div className="clock">
          <div className="text"> {startTime != 0 ? startTime : "GET READY"}</div>
          <div
            className={
              startTime == 0 ? "show start-img-background" : "start-img-background"
            }
          ></div>
          <CircularProgressbar
            counterClockwise={true}
            value={startTime }
            maxValue={firstTimeStartTime}
            strokeWidth={14}
            background={false}
            styles={buildStyles({
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
              // Colors
              pathColor: "#F5DB1E",
              trailColor: "transparent",
            })}
          />
        </div>
       
        <div className="mobile-pricefund">
          <div className="money">{formatMoney(20)}</div>
          <div className="text">Prize Fund</div>
        </div>
      </div>
    </div>
  );
}


