import axios from "axios";
import Config from "../Config";
import { getAuthUser } from "./HelperMethods";

export default class ApiService {

  async onPopupCancel(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/popup/cancel/" + gameId);
  }
  async confirmPass(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/pass/confirm/" + gameId);
  }
  async cashOut(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/popup/cashout/" + gameId);
  }
  async onPass(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/pass/" + gameId);
  }
  async auth(params) {
    let ax = await this.ax();
    return ax.post("/auth", params);
  }

  async qeueTimmer(data) {
    let ax = await this.ax();
    return ax.post("/Entry/QeueTimmer",data);
  }

  async registerPlayerForNextGame(data) {
    let ax = await this.ax();
    return ax.post("/Entry/RegisterForNextGame", data);
  }
  async getBalance() {
    let ax = await this.ax();
    return ax.get("/entry/PlayerBalance/");
  }
  async getInitData(data) {
    let ax = await this.ax();
    return ax.post("/Entry/InitData", data);
  }

  async getGamePlayers(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/GamePlayers/" + gameId);
  }


  async currentQuestions(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/CurrentQuestions/" + gameId);
  }

  async playerSendAnswer(data) {
    let ax = await this.ax();
    return ax.post("/Game/PlayerSendAnswer", data);
  }

  async getGameStatus(gameId: number) {
    let ax = await this.ax();
    return ax.get("/Game/GetGameStatus/" + gameId);
  }

  async getGameEndData(gameId) {
    let ax = await this.ax();
    return ax.get("/Game/FinalScore/" + gameId);
  }

  async getChat(data) {
    let ax = await this.ax();
    return ax.post("/entry/get", data);
  }

  // async sendChat(data) {
  //   let ax = await this.ax();
  //   return ax.get("/chat/add/" + data);
  // }

  async addChat(data) {
    let ax = await this.ax();
    return ax.post("/chat/add", data);
  }



  async ax() {
    let config = new Config();
    let url = config.getBaseURL();
    //let url = "https://localhost:44303";
    const jwt = getAuthUser();

    return axios.create({
      baseURL: url,
      timeout: 990000,
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    });
  }
}
