import "./App.scss";
import Game from "./pages/Game/Game";
import { Fragment, useContext, useEffect, useState } from "react";
import Entry from "./pages/Entry/Entry";
import { HashRouter, Route, Switch } from "react-router-dom";
import TempBingoWindow from "./pages/TempBingoWindow/TempBingoWindow";
import "react-circular-progressbar/dist/styles.css";
import GameEnd from "./pages/GameEnd/GameEnd";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { GameIdStore, IsAuth } from "./store/Store";
import InitApp from "./pages/InitApp";
import { urlParams } from "./Services/HelperMethods";
import RotateDeviceMessage from "./components/RotateDeviceMessage";
import { getServerType } from "./Config";
import Countdown from "./pages/Countdown/Countdown";

function App() {
  const [isAuth, setIsAuth] = useContext(IsAuth);
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [theme, setTheme] = useState("");


  useEffect(() => {
    const param = urlParams();
    // if (param.gameType == '23008') {
    //     setTheme('music');
    // } else if (param.gameType == '23010') {
    //     setTheme('noughties');
    // } else if (param.gameType == '23007') {
    //     setTheme('eighties');
    // } else if (param.gameType == '23009') {
    //     setTheme('nineties');
    // } else if (param.gameType == '23006') {
    //     setTheme('movies');
    // }

    if (param.gameType == "19") {
      setTheme("music");
    } else if (param.gameType == "21") {
      setTheme("movies");
    } else if (param.gameType == "23") {
      setTheme("eighties");
    } else if (param.gameType == "22") {
      setTheme("nineties");
    } else if (param.gameType == "20") {
      setTheme("noughties");
    }

    console.log("theme", theme);
  }, []);

  useEffect(() => {
    console.log("isauth", isAuth);
  }, [isAuth]);

  if (getServerType() === "playtech") {
    return (
      <div className={"App " + theme}>
        <div>
          <ToastContainer />
          <HashRouter>
            <RotateDeviceMessage />
            <Route path="*" exact component={InitApp} />
            {isAuth ? (
              <Switch>
                <Route path={["/", "/ App*"]} exact component={Entry} />
                <Route path="/count" exact component={Countdown} />
                <Route path="/game/:gameId" exact component={Game} />
                <Route path="/bingo" exact component={TempBingoWindow} />
                <Route path="/end/:gameId" exact component={GameEnd} />
              </Switch>
            ) : (
              <div className="layers">
                <Fragment />
              </div>
            )}
          </HashRouter>
        </div>
        <div className="footer-x">{appSyncGameId}</div>
      </div>
    );
  }
  // return (
  //     // eighties nineties noughties movies music
  //     <div className={"App " + theme}>
  //         <div>
  //             <ToastContainer/>
  //             <HashRouter>
  //                 <RotateDeviceMessage/>
  //                 <Route path="*" exact component={InitApp}/>
  //                 {isAuth ? (
  //                     <Switch>
  //                         <Route path={["/", "/ App*"]} exact component={Entry}/>
  //                         <Route path="/count/:gameId" exact component={Countdown}/>
  //                         <Route path="/game/:gameId" exact component={Game}/>
  //                         <Route path="/bingo" exact component={TempBingoWindow}/>
  //                         <Route path="/end/:gameId" exact component={GameEnd}/>
  //                     </Switch>
  //                 ) : (
  //                     <div className="layers">
  //                         <Fragment/>
  //                     </div>
  //                 )}
  //             </HashRouter>
  //         </div>
  //         <div className="footer-x">{appSyncGameId}</div>
  //     </div>
  // );
}

export default App;
