import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Card from "../../components/Card";
import {
  fancyTimeFormat,
  formatMoney,
  getServerName,
  handleErrors,
  showError,
} from "../../Services/HelperMethods";
import "./Game.scss";
import * as ProgressBar from "progressbar.js";
import { useHistory, useParams, Link } from "react-router-dom";
import { subscribeGame } from "../Subscriptions/AppSyncSub";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ApiService from "../../Services/ApiServices";
import {
  CurrentQuestionNumber,
  FirstAnswerPercentage,
  GameAnswerStore,
  GameStatus,
  LastCorrectAnswer,
  LastQuestionStore,
  PopupPrice,
  PopupStatus,
  PopupTimer,
  QuestionTimer,
  SecondAnswerPercentage,
  StartTimerStore,
  TotalJackpot,
  TotalPlayers,
} from "../../store/Store";
import moment from "moment";
import Reactions from "../../components/Reactions/Reactions";
import { toast } from "react-toastify";

export default function Game() {
  const [selected, setSelected] = useState("");
  const history = useHistory();
  const { gameId } = useParams() as any;
  const [questionText, setQuestionText] = useContext(LastQuestionStore);
  const [firstAnswer, setFirstAnswer, secondAnswer, setSecondAnswer] =
    useContext(GameAnswerStore);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useContext(
    CurrentQuestionNumber
  );
  const [questionTimer] = useContext(QuestionTimer as any) as any;
  const [lastCorrectAnswer, setLastCorrectAnswer] =
    useContext(LastCorrectAnswer);

  const [popupTimer] = useContext(PopupTimer);
  const [popupStatus] = useContext(PopupStatus);
  const [popupPrice] = useContext(PopupPrice);

  const [showClaimPriceModal, setShowClaimPriceModal] = useState(false);
  const [showPassModal, setShowPassModal] = useState(false);
  const apiService = new ApiService();
  const [appSyncGameId, setAppSyncGameId] = useState("");
  const firstButtonRef = useRef(null);
  const secondButtonRef = useRef(null);

  const [passAlreadyUsed, setPassAlreadyUsed] = useState(false);
  const [fakeTimer, setFakeTimer] = useState<number>(6);

  useEffect(() => {
    getGameStatus();
  }, []);

  async function getGameStatus() {
    apiService.getGameStatus(gameId).then((data: any) => {
      data = data.data;
      console.log("game status", data);
      setPassAlreadyUsed(data.isPassUsed);
    });
  }

  useEffect(() => {
    if (gameId && !appSyncGameId) {
      setAppSyncGameId(gameId);
    }
    return () => {
      //reset
      setShowClaimPriceModal(false);
      setQuestionText("");
      setLastCorrectAnswer("");
      setCurrentQuestionNumber(0);
      setFirstAnswer("");
      setSecondAnswer("");
      setPassAlreadyUsed(false);
    };
  }, [appSyncGameId]);

  useEffect(() => {
    if (popupStatus === "hold.cashout") {
      setShowClaimPriceModal(true);
    } else if (popupStatus === "") {
      setShowClaimPriceModal(false);
    }
  }, [popupStatus]);

  useEffect(() => {
    if (questionText) {
      setSelected("");
    }
  }, [questionText]);

  useEffect(() => {
    if (lastCorrectAnswer) {
      setTimeout(() => {
        let $ = (window as any).$;
        const btns = [firstButtonRef?.current, secondButtonRef?.current];
        btns.forEach((btn) => {
          if ($(btn).hasClass("wrong")) {
            $(btn).addClass("wrong-blank");
          }
          if ($(btn).hasClass("correct")) {
            $(btn).addClass("correct-blank");
          }
        });
      }, 1500);
    }
  }, [lastCorrectAnswer]);

  async function selectAnswer(answer) {
    setSelected(answer);
    try {
      await apiService.playerSendAnswer({
        answer,
        gameId,
      });
    } catch (error) {
      handleErrors(error);
      setSelected("");
    }
  }

  async function onPressPass() {
    if (passAlreadyUsed) {
      setShowPassModal(false);
      return;
    }
    setShowPassModal(true);
  }

  async function onPassCancel() {
    setShowPassModal(false);
  }

  async function onPopupCancel() {
    try {
      let result = await apiService.onPopupCancel(gameId);
      console.log(result);
    } catch (error) {
      handleErrors(error);
    }
  }

  async function confirmPass() {
    try {
      let result = await apiService.confirmPass(gameId);
      console.log(result, "confirmPass result");
      setShowPassModal(false);
      setPassAlreadyUsed(true);
    } catch (error) {
      handleErrors(error);
    }
  }

  async function claimPrice() {
    try {
      let result = await apiService.cashOut(gameId);
      console.log(result, "claimPrice");
    } catch (error) {
      handleErrors(error);
    }
  }

  function btnTextUI(answer) {
    return (
      <>
        <div
          className="text"
          style={{
            textAlign: answer?.length > 20 ? "center" : "center",
            paddingBottom: answer?.length > 20 ? "18px" : "40px",
          }}
        >
          {answer}
        </div>
      </>
    );
  }
  return (
    <div className="layers" style={{ overflowY: "hidden" }}>
      {/* popup modals */}
      {showPassModal && (
        <div className="modal pass-modal">
          <div className="content-wrap">
            <div className="content">
              If you pass the <span>Jackpot</span> is reduced by
            </div>
            <div className="number" />
          </div>
          <div className="content2">
            <div className="clock">{fancyTimeFormat(questionTimer)}</div>
            <div className="content sm">Do you with to continue</div>
          </div>
          <div className="btn-wrapper">
            <div className="btn cancel" onClick={onPassCancel} />
            <div className="btn use-the-pass" onClick={confirmPass} />
          </div>
        </div>
      )}

      {showClaimPriceModal && (
        <div className="modal claim-price-modal">
          <div className="content-wrap">
            <div className="content">You may now cash out with</div>
            <div className="number">{formatMoney(popupPrice)}</div>
          </div>
          <div className="content2">
            <div className="clock">00:{popupTimer}</div>
            <div className="content sm">Do you want to cash out?</div>
          </div>
          <div className="btn-wrapper">
            <div className="btn play-on" onClick={onPopupCancel} />
            <div className="btn claim-price" onClick={claimPrice}>
              <div className="text">
                Claim the {formatMoney(popupPrice)} and <br /> end the game
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="game-page">
        <div className="top">
          {Array(20)
            .fill(0)
            .map((v, i) => {
              let clsName = "answered";
              if (i < currentQuestionNumber) {
                clsName = "remain";
              }
              if (i == currentQuestionNumber) {
                clsName = "current";
              }
              return (
                <div key={i} className={clsName}>
                  {i + 1}
                </div>
              );
            })}
        </div>
        {passAlreadyUsed ? (
          <div></div>
        ) : (
          <div className="pass" onClick={onPressPass} />
        )}

        <div className="main-area">
          <div className="question">{questionText}</div>
          <div className="card-wrapper">
            <div className="counterBar">
              <CircularProgressbar
                counterClockwise={true}
                value={questionTimer}
                maxValue={5}
                strokeWidth={25}
                background={false}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathTransitionDuration: 0.5,
                  pathColor: "#00FFFF",
                  trailColor: "transparent",
                })}
              />
            </div>
            <div className="podium" />
            <div className="cwrap">
              <div
                ref={firstButtonRef}
                onClick={() => {
                  if (firstAnswer && !selected) {
                    selectAnswer(firstAnswer);
                  }
                }}
                className={getBtnClassName("left", firstAnswer, firstButtonRef)}
              >
                {btnTextUI(firstAnswer)}
              </div>
              <div
                ref={secondButtonRef}
                onClick={() => {
                  if (secondAnswer && !selected) {
                    selectAnswer(secondAnswer);
                  }
                }}
                className={getBtnClassName(
                  "right",
                  secondAnswer,
                  secondButtonRef
                )}
              >
                {btnTextUI(secondAnswer)}
              </div>
            </div>
          </div>
        </div>
        <div className="left-bar">
          <div className="podium" />
          <div className="img" />
        </div>
        <div className="right-bar">
          <div className="podium" />
          <div className="img" />
        </div>
      </div>
    </div>
  );

  function getBtnClassName(position, answer, btnRef) {
    let cls = "card";
    cls += position == "left" ? " left" : " right";
    // console.log(answer, "a", lastCorrectAnswer);

    if (selected == answer && selected != "") {
      cls += " active";
    }

    if (lastCorrectAnswer && answer) {
      if (lastCorrectAnswer == answer) {
        cls += " correct";
      } else if (lastCorrectAnswer) {
        cls += " wrong";
      }
    }
    return cls;
  }
}
