import "../App.scss";
import { Fragment, useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";

import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ApiService from "../Services/ApiServices";
import {
  getCurrentIP,
  getPlayerBalanceStr,
  getServerName,
  handleErrors,
  setAuthUser,
  urlParams,
} from "../Services/HelperMethods";
import {
  ChatList,
  CurrentQuestionNumber,
  FirstAnswerPercentage,
  GameAnswerStore,
  GameIdStore,
  GameStatus,
  IsAuth,
  LastCorrectAnswer,
  LastQuestionStore,
  PlayerBalanceStore,
  PlayerGameStatus,
  QuestionTimer,
  KillSwitch,
  SecondAnswerPercentage,
  StartTimerStore,
  TotalJackpot,
  TotalPlayers,
  PopupTimer,
  PopupStatus,
  PopupPrice,
} from "../store/Store";
import { subscribeGame } from "../pages/Subscriptions/AppSyncSub";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function IntiApp() {
  const apiService = new ApiService();
  const history = useHistory();

  const [availableMoney, setAvailableMoney] = useContext(PlayerBalanceStore);
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [startTime, setStartTime] = useContext(StartTimerStore);
  // const [totalPlayers, setTotalPlayers] = useContext(TotalPlayers);
  // const [totalJackpot, setTotalJackpot] = useContext(TotalJackpot);
  const [status, setStatus] = useContext(GameStatus);
  const [sub, setSub] = useState(null as any);
  const [isAuth, setIsAuth] = useContext(IsAuth);
  const [questionText, setQuestionText] = useContext(LastQuestionStore);
  const [questionTimer, setQuestionTimer] = useContext(QuestionTimer);
  const [lastCorrectAnswer, setLastCorrectAnswer] =
    useContext(LastCorrectAnswer);
  const [playerGameStatus, SetPlayerGameStatus] = useContext(PlayerGameStatus);
  const [firstAnswerPercentage, SetFirstAnswerPercentage] = useContext(
    FirstAnswerPercentage
  );
  const [secondAnswerPercentage, SetSecondAnswerPercentage] = useContext(
    SecondAnswerPercentage
  );
  const [currentQuestionNumber, setCurrentQuestionNumber] = useContext(
    CurrentQuestionNumber
  );
  const [firstAnswer, setFirstAnswer, secondAnswer, setSecondAnswer] =
    useContext(GameAnswerStore);
  let timer = null as any;
  const [chatList, setChatList] = useContext(ChatList);
  const [killSwitch, setKillSwitch] = useContext(KillSwitch);
  const [popupTimer, setPopupTimer] = useContext(PopupTimer);
  const [popupStatus, setPopupStatus] = useContext(PopupStatus);
  const [popupPrice, setPopupPrice] = useContext(PopupPrice);
  // const [timmer] = useState(int as )

  useEffect(() => {
    console.log("Starting...", window?.location.href);

    auth(async () => {
      await getPlayerBalance();
      await getInitData();
      setIsAuth(true);
      console.log("App Started!");
    });

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(()=>{

  })

  useEffect(() => {
    if (questionText) {
      console.log("question update!");
      getGameStatus();
      setLastCorrectAnswer("");
      setFirstAnswer("");
      setSecondAnswer("");
      SetFirstAnswerPercentage("");
      SetSecondAnswerPercentage("");
    }
  }, [questionText]);

  useEffect(() => {
    if (appSyncGameId) {
      // console.log('subscribe: ', appSyncGameId);
      sub?.unsubscribe();
      subscribeGame(appSyncGameId, async (d) => {
        console.log("AppSync", d.data);
        setSub(d.unsub);

        if (d.data.popupStatus != null) {
          setPopupStatus(d.data.popupStatus);
          setPopupTimer(d.data.popupCountTimer);
          setPopupPrice(d.data.popupPrice);
        }

        //in game
        if (d.data.questionCountTimer != null) {
          setQuestionTimer(d.data.questionCountTimer);
        }
        if (d.data.currentQuestionNumber) {
          setCurrentQuestionNumber(d.data.currentQuestionNumber);
        }
        let a = JSON.parse(d.data.lastAnswers);
        if (a?.[0]) {
          setFirstAnswer(a?.[0]);
          setSecondAnswer(a?.[1]);
        }
        if (d.data.lastQuestion) {
          setQuestionText(d.data.lastQuestion);
        }
        if (d.data.lastCorrectAnswer) {
          setLastCorrectAnswer(d.data.lastCorrectAnswer);
        }

        if (d.data.status == "Finished") {
          history.push("/end/" + appSyncGameId);
        }

        if (d.data.status == "CrashedAfterStarted") {
          history.push("/");
        }
      });
    }
  }, [appSyncGameId]);

  async function getInitData() {
    try {
      let data = (await apiService.getInitData({
        serverName: getServerName(),
      })) as any;
      data = data.data;
      setKillSwitch("");
    } catch (error: any) {
      if (error?.response) {
        setKillSwitch(error?.response?.data?.message);
        console.log(error?.response);

        return;
      }
      // handleErrors(error);
    }
  }

  async function auth(successCb) {
    try {
      //auth
      let urlParam = urlParams() as any;
      // const currentIp = (await getCurrentIP()) as any;
      // urlParam.clientIp = currentIp.clientIp;
      let authData = await apiService.auth(urlParam);
      setAuthUser(authData);
      successCb();
    } catch (error) {
      handleErrors(error);
    }
  }

  async function getPlayerBalance() {
    let playerBalance = await getPlayerBalanceStr();
    setAvailableMoney(playerBalance);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      getPlayerBalance();
    }, 30000);
  }

  async function getGameStatus() {
    try {
      let data = (await apiService.getInitData({
        serverName: getServerName(),
      })) as any;
      data = data.data;
      console.log("gamestatus", data);
      if (data.playerGameStatus == "Init") {
        history.push("/count/" + data.playerGameAppSyncId);
      } else if (
        data.playerGameStatus == "Current" ||
        data.playerGameStatus == "Started"
      ) {
        history.push("/game/" + data.playerGameAppSyncId);
      }
      return data;
    } catch (error) {
      // handleErrors(error);
    }
  }

  return <Fragment />;
}
