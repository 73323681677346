import React from "react";
import { BrowserView, MobileView } from 'react-device-detect';

export default function RotateDeviceMessage() {
    return (
        <>
            {/* <MobileView>
                <div className="rotate-dev-msg">
                    <h2>Please rotate your device</h2>
                </div>
            </MobileView> */}
        </>
    )
}