import React, { Fragment, useContext, useEffect, useState } from "react";
import { formatMoney } from "../../Services/HelperMethods";
import "./GameEnd.scss";
import { useParams, useHistory } from "react-router-dom";
import PrizeFundBanner from "../../components/PrizeFundBanner";
import ApiService from "../../Services/ApiServices";
import Reactions from "../../components/Reactions/Reactions";
import { GameIdStore } from "../../store/Store";
import ExitQuizButton from "../../components/ExitQuizButton";

export default function GameEnd() {
  const history = useHistory();
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [isCashOut , setIsCashOut] = useState(false);
  const apiService = new ApiService();
  const { gameId } = useParams() as any;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getGameStatus();
  }, [])

  async function getGameStatus() {
    setIsLoading(true);
    apiService.getGameStatus(gameId).then((data: any) => {
      data = data.data;
      console.log('game end', data);
      setTotalJackpot(data.jackpot);
      setIsCashOut(data.isCashOut);
      setIsLoading(false);
    });
  }

  if (isLoading) {
    return <div className="layers"></div>
  }

  return (
    <div className="layers">
      {totalJackpot ? <div className="game-end-claim-price">
        <div className="game-over-wrap">
          <div className="claim-price-text" />
          {isCashOut?<div className="subtitle">Claim your cash out prize!</div>:<div className="subtitle">You won the jackpot!</div>}
        </div>
        <div className="btn btn-claim" onClick={() => {
          history.push("/")
        }}>
          <div className="text">
            Claim {formatMoney(totalJackpot)}
          </div>

        </div>
      </div> : <div className="game-end-page">
        <div className="game-over-wrap">
          <div className="game-over" />
        </div>

        <div className="btn-wrap">
          <div className="btn btn-quit" onClick={() => {
            window.close();
            console.log("close");
          }} />
          <div className="btn btn-play-again" onClick={() => {
            history.push("/")
          }}>
            <div className="text">
              Play again
            </div>
          </div>
        </div>
      </div>}

    </div>
  );
}
