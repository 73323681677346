/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFiftySinglePlayer = /* GraphQL */ `
  subscription OnCreateFiftySinglePlayer($id: String, $lastQuestion: String) {
    onCreateFiftySinglePlayer(id: $id, lastQuestion: $lastQuestion) {
      id
      createdDate
      status
      popupCountTimer
      popupStatus
      popupPrice
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
      chat
    }
  }
`;
export const onUpdateFiftySinglePlayer = /* GraphQL */ `
  subscription OnUpdateFiftySinglePlayer($id: String, $lastQuestion: String) {
    onUpdateFiftySinglePlayer(id: $id, lastQuestion: $lastQuestion) {
      id
      createdDate
      status
      popupCountTimer
      popupStatus
      popupPrice
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
      chat
    }
  }
`;
export const onDeleteFiftySinglePlayer = /* GraphQL */ `
  subscription OnDeleteFiftySinglePlayer($id: String, $lastQuestion: String) {
    onDeleteFiftySinglePlayer(id: $id, lastQuestion: $lastQuestion) {
      id
      createdDate
      status
      popupCountTimer
      popupStatus
      popupPrice
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
      chat
    }
  }
`;
