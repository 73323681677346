import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GeneralTop from "../../components/GeneralTop";
import ApiService from "../../Services/ApiServices";
import {
  formatMoney,
  getServerName,
  handleErrors,
  validateBot,
} from "../../Services/HelperMethods";
import "./Entry.scss";
import moment from "moment";
import {
  GameIdStore,
  PlayerBalanceStore,
  PlayerGameStatus,
  KillSwitch,
  StartTimerStore,
  TotalJackpot,
  TotalPlayers,
} from "../../store/Store";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";

export default function Entry() {
  const history = useHistory();
  const apiService = new ApiService();
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPlayers, setTotalPlayers] = useContext(TotalPlayers);
  const [totalJackpot, setTotalJackpot] = useContext(TotalJackpot);
  const [startTime, setStartTime] = useContext(StartTimerStore);
  const [appSyncGameId, setAppSyncGameId] = useContext(GameIdStore);
  const [availableMoney] = useContext(PlayerBalanceStore);
  const [playerGameStatus, SetPlayerGameStatus] = useContext(PlayerGameStatus);
  const [killSwitch, setKillSwitch] = useContext(KillSwitch);
  let timeout;

  const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    if (badge && badge instanceof HTMLElement) {
      badge.style.visibility = show ? "visible" : "hidden";
    }
  };

  useEffect(() => {
    console.log(availableMoney, "money");
    toggleCaptchaBadge(true);
    return () => toggleCaptchaBadge(false);
  }, []);

  async function ontimmer() {
    setIsLoading(true);
    try {
      let result = (await apiService.qeueTimmer({Server : getServerName()})) as any;
      console.log(result.data.timmer, "timer");
      if(result.data.timmer == 0)
      {
        registerForNextGame();
        return;
      }      
      setStartTime(result.data.timmer)
      history.push('/count/');
      
    } catch (error: any) {
      console.error(error);
      if (error.response?.data?.type == "same_user") {
      } else {
      }
      setIsLoading(false);
    }
  }
  async function registerForNextGame() {
    setIsLoading(true);
    validateBot(async (token) => {
      try {
        let result = (await apiService.registerPlayerForNextGame({
          gCaptcha: token,
          Server: getServerName(),
        })) as any;
        result = result.data;
        const appsyncId = result.authResult.appSyncId;
        setAppSyncGameId(appsyncId);
        history.push("/game/" + appsyncId);
      } catch (error: any) {
        console.error(error);
        if (error.response?.data?.type == "same_user") {
          // history.push("/game/" + appSyncGameId);
          // toast.info("Already in the game, No money has been deducted")
        } else {
          // handleErrors(error);
        }
        setIsLoading(false);
      }
    }, "PlayGame");
  }

  return (
    <div className="layers">
      <div className="entry-page">
        <div className="music-image" />
        <GeneralTop availableMoney={availableMoney} />
        <div className="content-wrap">
          <div className="fiftyimg"></div>
          <div className="game-logo"></div>
          <div className="description">
            Choose the correct answer quickly to win the jackpot! <br /> You get
            one pass to help you. Cashout if the going gets tough.
          </div>
          <div className="play-button-wrap">
            <div className="price-fund" />
            <div className="cup-image" />
            <div
              className={killSwitch ? "play-button disabled" : "play-button"}
              onClick={() => {
                if (!isLoading) {
                  //registerForNextGame();
                  ontimmer();
                }
              }}
            >
              {isLoading ? (
                <div className="text">
                  <Loader
                    type="TailSpin"
                    color="white"
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                <div className="text">PLAY {formatMoney(1)}</div>
              )}
            </div>
            {killSwitch && (
              <div className="server-notice">
                <div className="text">{killSwitch}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js?render=6LdiRy8kAAAAANsgSybFjf0wIBFGB0DDClEuwyY6"></script>
        </Helmet>
      </div> */}
    </div>
  );
}
