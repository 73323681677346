import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import awsconfig from "./AppSync/src/aws-exports";
import Amplify from "aws-amplify";
import Store from "./store/Store";

Amplify.configure(awsconfig);

ReactDOM.render(
    <Store>
      <App />
    </Store>,
  document.getElementById("root")
);
